<template>
  <div class="bg-grey-4">
    <div class="row">
      <div
        class="q-mr-auto q-ml-auto"
        :class="{
          'col-9': $q.screen.width > 700,
          'col-11': $q.screen.width <= 700,
        }"
      >
        <div
          class="bg-yellow q-pa-md q-mb-lg default-rounded text-justify"
          v-if="step == 1"
        >
          <q-icon name="person" size="20px" class="q-pa-sm q-pr-sm" />
          <span
            v-html="$t('account.pending_docs_message')"
            style="font-size: 11pt;"
          />
        </div>
        <q-stepper
          flat
          animated
          v-model="step"
          color="primary"
          class="q-mb-lg default-rounded bg-grey-4"
          :header-class="'bg-white'"
        >
          <q-step
            :key="i"
            :name="step.name"
            :done="step.done"
            :icon="step.icon"
            :title="step.title"
            :done-icon="step.done_icon"
            v-for="(step, i) in stepsConfig(step)"
          />
        </q-stepper>
        <div style="margin-top: -40px;">
          <div v-if="step == 1">
            <edit />
          </div>
          <div v-if="step == 2">
            <message-default-component
              :data="{
                title: $t('account.little_time'),
                message: $t('account.data_analyzing'),
              }"
            >
              <q-spinner-hourglass color="grey" size="10em" />
            </message-default-component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MessageDefaultComponent from "./components/MessageDefaultComponent.vue";
import { ref, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import edit from "./edit.vue";

export default defineComponent({
  name: "EditPendingPage",

  components: { edit, MessageDefaultComponent },

  setup() {
    const { t } = useI18n();
    const step = ref(localStorage.getItem("hasPendDoc"));

    const stepsConfig = (step) => {
      return [
        {
          name: "1",
          done: step >= 1,
          icon: "update",
          done_icon: "update",
          title: t("account.update_data"),
        },
        {
          name: "2",
          done: step == 2,
          icon: "query_stats",
          done_icon: "query_stats",
          title: t("account.analyzing_data"),
        },
      ];
    };

    return {
      step,
      stepsConfig,
    };
  },
});
</script>
