<template>
  <div class="bg-white q-pa-lg default-rounded">
    <div class="bg-grey-3 default-rounded q-pa-lg">
      <div
        class="text-uppercase text-center q-mt-md title"
        v-html="data.title"
      />
      <div
        class="text-center q-mt-md text-center message"
        v-html="data.message"
      />
      <div class="text-center q-mt-lg q-mb-xl">
        <!-- location for the spinner -->
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "MessageDefaultComponent",

  props: {
    data: {
      type: Object,
      required: true,
    },
  },
});
</script>

<style scoped lang="scss">
.title {
  font-size: 30pt;
  font-weight: bold;
}
.message {
  font-size: 14pt;
}

@media screen and (max-width: 1368px) {
  .title {
    font-size: 24pt;
  }
  .message {
    font-size: 10pt;
  }
}

@media screen and (max-width: 1280px) {
  .title {
    font-size: 20pt;
  }
}

@media screen and (max-width: 700px) {
  .title {
    font-size: 14pt;
  }
  .message {
    font-size: 12pt;
    text-align: justify;
  }
}
</style>
